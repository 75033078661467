<template>
  <main class="main-box" @scroll="scroll">
    <header id="header" :class="showShadow?'show-shadow':''">
      <div class="container">
        <div id="logo" class="pull-left">
          <h1><a href="#body" class="scrollto">Text<span>come</span></a></h1>
        </div>
        <nav id="nav-menu-container">
          <ul class="nav-menu" style="touch-action: pan-y;">
            <li class="menu-active"><a href="#body">Home</a></li>
            <li><a href="#advantages">Advantages</a></li>
            <li><a href="#appraisal">Appraisal</a></li>
            <li><a href="#contact">Contact</a></li>
            <li><a href="#" @click="directLogin">Sign in</a></li>
          </ul>
        </nav>
      </div>
    </header>
    <section id="body">
      <div id="intro-carousel" class="body-background">
        <img src="imgs/1.jpg" />
      </div>
      <div class="intro-content">
        <h2>Making <span>,your ideas</span><br>happen!</h2>
        <div>
          <a class="btn btn-get-started scrollto" @click="directLogin">Get Started</a>
          <a href="#contact" class="btn btn-projects scrollto">Contact Us</a>
        </div>
      </div>
    </section>
    <section id="about" class="wow fadeInUp" style="visibility: visible; animation-name: fadeInUp;">
      <div class="container">
        <div class="row">
          <div class="about-img">
            <img src="imgs/about-img.jpg" alt="">
          </div>
          <div class="content">
            <h2>System Usage Guidelines</h2>
            <h3>
              These guidelines are essential for ensuring the security and proper use of our system. Please adhere to them to protect sensitive data and maintain system integrity. Thank you for your cooperation.
            </h3>
            <ul>
              <li>
                <i class="bi bi-check2-circle"></i>
                Keep Account Secure: Protect login details.
              </li>
              <li>
                <i class="bi bi-check2-circle"></i>
                Data Protection: Handle sensitive info carefully.
              </li>
              <li>
                <i class="bi bi-check2-circle"></i>
                Compliance: Follow laws and company policies.
              </li>
              <li>
                <i class="bi bi-check2-circle"></i>
                Appropriate Use: Use the system for authorized purposes only and treat others with respect.
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
    <section id="advantages">
      <div class="container">
        <div class="section-header">
          <h2>Advantages</h2>
          <p>Our system stands out from the rest due to its exceptional stability, ensuring that users have a seamless experience without frequent interruptions or downtime. The user interface is designed to be intuitive and user-friendly, making it easy for both novice and experienced users to interact with the system. Furthermore, the system is highly responsive, allowing for quick execution of commands and minimizing lag times. Additionally, our system also provides a convenient and efficient bulk messaging feature, enabling users to send out messages to multiple recipients simultaneously with just a few clicks.</p>
        </div>
        <div class="row">
          <div class="col-lg-6">
            <div class="box wow fadeInLeft" style="visibility: visible; animation-name: fadeInLeft;">
              <div class="icon">
                <i class="bi bi-hourglass-top"></i>
              </div>
              <div class="advantage-text">
                <h4 class="title"><a href="">Stablize</a></h4>
                <p class="description">We understand how frustrating it can be when technology fails, which is why we’ve invested significant resources into ensuring that our system is reliable and resilient. As a result, our users can enjoy a seamless experience.</p>
              </div>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="box wow fadeInRight" style="visibility: visible; animation-name: fadeInRight;">
              <div class="icon">
                <i class="bi bi-rocket-fill"></i>
              </div>
              <div class="advantage-text">
                <h4 class="title"><a href="">Quick response</a></h4>
                <p class="description">aced digital world, and have optimized our system to deliver rapid and efficient performance. Whether you’re sending messages, running automated processes, or conducting searches, you can rely on our system to respond quickly and reliably.</p>
              </div>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="box wow fadeInLeft" data-wow-delay="0.2s" style="visibility: visible; animation-delay: 0.2s; animation-name: fadeInLeft;">
              <div class="icon">
                <i class="bi bi-people-fill"></i>
              </div>
              <div class="advantage-text">
                <h4 class="title"><a href="">Group message</a></h4>
                <p class="description">
                  Enabling users to communicate and collaborate with large groups of people all at once. Our group messaging feature provides a convenient and efficient way to stay connected and stay on top of important tasks.
                </p>
              </div>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="box wow fadeInRight" data-wow-delay="0.2s" style="visibility: visible; animation-delay: 0.2s; animation-name: fadeInRight;">
              <div class="icon">
                <i class="bi bi-flower3"></i>
              </div>
              <div class="advantage-text">
                <h4 class="title"><a href="">Friendly interactive interface</a></h4>
                <p class="description">
                  
The system interface features a user-friendly design, ensuring a seamless and intuitive interaction experience. Its interface is intuitively designed, making it easy for users to navigate and access various features and functionalities.   
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section id="appraisal" class="wow fadeInUp" style="visibility: visible; animation-name: fadeInUp;">
      <div class="container">
        <div class="section-header">
          <h2>appraisals</h2>
          <p>
            The following is an overview of the user feedback data collected from our system. This data provides valuable insights into user experiences, preferences, and satisfaction levels. Through thorough analysis, we aim to continually enhance the system’s functionality and user experience based on our users’ feedback.
          </p>
        </div>
        <div class="owl-carousel appraisals-carousel owl-loaded owl-drag">
          <div class="owl-stage-outer">
            <div class="owl-stage">
              <div class="owl-item">
                <div class="appraisal-item">
                  <p>
                    <img src="imgs/quote-sign-left.png" class="quote-sign-left" alt="">
                    I’ve been using the system for a few months now, and I must say I’m thoroughly impressed. The interface is so easy to navigate, and the features are incredibly helpful for streamlining my workflow. Definitely a game-changer for me!
                    <img src="imgs/quote-sign-right.png" class="quote-sign-right" alt="">
                  </p>
                  <img src="img/appraisal-3.jpg" class="appraisal-img" alt="">
                  <i class="user-icon bi bi-person-hearts"></i>
                  <h3>K***is</h3>
                </div>
              </div>
              <div class="owl-item">
                <div class="appraisal-item">
                  <p>
                    <img src="imgs/quote-sign-left.png" class="quote-sign-left" alt="">
                    I love how intuitive this system is to use. From creating projects to collaborating with team members, everything feels seamless and efficient. It has definitely boosted our team’s productivity and communication. Highly recommend it!
                    <img src="imgs/quote-sign-right.png" class="quote-sign-right" alt="">
                  </p>
                  <img src="img/appraisal-4.jpg" class="appraisal-img" alt="">
                  <i class="user-icon bi bi-person-hearts"></i>
                  <h3>Br***on</h3>
                </div>
              </div>
              <div class="owl-item">
                <div class="appraisal-item">
                  <p>
                    <img src="imgs/quote-sign-left.png" class="quote-sign-left" alt="">
                    The system has exceeded my expectations in terms of usability. I appreciate the attention to detail in the design, making it visually appealing and user-friendly. It’s made my work much more organized and manageable. Great job to the developers!
                    <img src="imgs/quote-sign-right.png" class="quote-sign-right" alt="">
                  </p>
                  <img src="img/appraisal-5.jpg" class="appraisal-img" alt="">
                  <i class="user-icon bi bi-person-hearts"></i>
                  <h3>La**on</h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section id="contact" class="wow fadeInUp" style="visibility: visible; animation-name: fadeInUp;">
      <div class="container">
        <div class="section-header">
          <h2>Contact Us</h2>
          <p>
            If you encounter any system bugs during use, you can use Google Email to contact us. Please fill in your email address and question title when contacting us again.
          </p>
        </div>
      </div>
      <div class="container">
        <div class="form">
          <div class="form-row">
            <div class="form-group col-md-6">
              <input v-model="username" type="text" name="name" class="form-control" id="name" placeholder="Your Name" data-rule="minlen:4" data-msg="Please enter at least 4 chars">
              <div class="validation"></div>
            </div>
            <div class="form-group col-md-6">
              <input v-model="email" type="email" class="form-control" name="email" id="email" placeholder="Your Email" data-rule="email" data-msg="Please enter a valid email">
              <div class="validation"></div>
            </div>
          </div>
          <div class="form-group">
            <input v-model="title" type="text" class="form-control" name="subject" id="subject" placeholder="Subject" data-rule="minlen:4" data-msg="Please enter at least 8 chars of subject">
            <div class="validation"></div>
          </div>
          <div class="form-group">
            <textarea v-model="content" class="form-control" name="message" rows="5" data-rule="required" data-msg="Please write something for us" placeholder="Message"></textarea>
            <div class="validation"></div>
          </div>
          <div class="text-center"><button type="submit" @click="commitQeustion">Send Message</button></div>
        </div>
      </div>
    </section>
    <footer id="footer">
      <div class="container">
        <div class="copyright">
          © Copyright <strong>Textcome</strong>. All Rights Textcome
        </div>
      </div>
    </footer>
  </main>
</template>
<script>
import { addQuestion } from '@/api/question'

export default {
  data() {
    return {
      showShadow: false,
      username: '',
      email: '',
      title: '',
      content: ''
    }
  },
  methods: {
    homeClick() {
      location.href = '/'
    },
    directLogin() {
      location.href = '/login'
    },
    scroll(e) {
      this.showShadow = e.target.scrollTop > 0
    },
    commitQeustion() {
      const data = {
        username: this.username,
        email: this.email,
        title: this.title,
        content: this.content
      }
      if (this.username && this.email && this.title && this.content) {
        addQuestion(data).then(() => {
          this.$message.success('Commit success.')
        }).catch((e) => {
          this.$message.error('Commit failed, ' + e.message)
        })
      } else {
        this.$message.error('Please check your commit info.')
      }
    }
  }
}
</script>
<style rel="stylesheet/scss" lang="scss">
  .main-box {
    * {
      box-sizing: border-box;
    }
    .show-shadow {
      box-shadow: 0px 6px 9px 0px rgba(0, 0, 0, 0.06);
      right: 0px !important;
    }
    overflow-y: scroll;
    height: 100%;
    #header {
      position: fixed;
      top: 0px;
      right: 9px;
      width: 100%;
      padding: 20px 0;
      transition: all 0.2s;
      z-index: 997;
      background: #fff;
      .container {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
      }
      #logo {
        h1 {
          font-size: 42px;
          margin: 0;
          padding: 0;
          line-height: 1;
          font-family: "Montserrat", sans-serif;
          font-weight: 700;
          a {
            color: #0c2e8a;
            line-height: 1;
            display: inline-block;
            text-decoration: none;
            span {
              color: #50d8af;
            }
          }
        }
      }
      #nav-menu-container {
        float: right;
        margin: 0;
        .nav-menu > li {
          margin-left: 10px;
          float: left;
          position: relative;
          white-space: nowrap;
        }
        .nav-menu a {
          padding: 10px 8px;
          text-decoration: none;
          display: inline-block;
          color: #555;
          font-family: "Raleway", sans-serif;
          font-weight: 700;
          font-size: 14px;
          outline: none;
          transition: 0.5s;
          &:hover {
            color: #50d8af;
          }
        }
        .menu-active > a {
          color: #50d8af;
        }
        .nav-menu, .nav-menu * {
          margin: 0;
          padding: 0;
          list-style: none;
        }
      }
    }
    #body {
      margin-top: 84px;
      height: 60vh;
      position: relative;
      box-sizing: content-box;
      display: flex;
      align-items: center;
      justify-content: center;
      .intro-content h2 {
        color: #0c2e8a;
        margin-bottom: 30px;
        font-size: 64px;
        font-weight: 700;
        span {
          color: #50d8af;
          text-decoration: underline;
        }
      }
      .intro-content {
        z-index: 10;
        .btn {
          font-family: "Raleway", sans-serif;
          font-size: 15px;
          font-weight: bold;
          letter-spacing: 1px;
          display: inline-block;
          padding: 10px 32px;
          border-radius: 2px;
          transition: 0.5s;
          margin: 10px;
          color: #fff;
          text-decoration: none;
          cursor: pointer;
        }
        .btn-get-started {
          background: #0c2e8a;
          border: 2px solid #0c2e8a;
        }
        .btn-get-started:hover {
          background: none;
          color: #0c2e8a;
        }
        .btn-projects {
          background: #50d8af;
          border: 2px solid #50d8af;
        }
        .btn-projects:hover {
          background: none;
          color: #50d8af;
        }
      }
      .body-background {
        position: absolute;
        z-index: 9;
        inset: 0px;
        opacity: 0.5;
        img {
          height: 100%;
          width: 100%;
          user-select: none;
          -webkit-user-drag: none;
        }
      }
    }
    #about {
      padding: 60px 0 30px 0;
      .row {
        display: flex;
        align-items: center;
      }
      .about-img {
        flex: 0 0 50%;
        max-width: 50%;
        position: relative;
        min-height: 1px;
        padding-right: 15px;
        padding-left: 15px;
        img {
          margin-left: -15px;
          max-width: 100%;
        }
      }
      .content {
        flex: 0 0 50%;
        max-width: 50%;
        text-align: left;
        h2 {
          color: #0c2e8a;
          font-weight: 700;
          font-size: 36px;
          font-family: "Raleway", sans-serif;
        }
        h3 {
          color: #555;
          font-weight: 300;
          font-size: 18px;
          line-height: 26px;
          font-style: italic;
        }
        ul {
          list-style-type: none;
          padding-left: 0px;
        }
        i {
          font-size: 20px;
          padding-right: 4px;
          color: #50d8af;
        }
      }
    }
    #advantages {
      text-align: left;
      .section-header h2 {
        font-size: 32px;
        color: #0c2e8a;
        text-transform: uppercase;
        font-weight: 700;
        position: relative;
        &::before {
          content: '';
          position: absolute;
          display: block;
          width: 50px;
          height: 3px;
          background: #50d8af;
          bottom: 0;
          left: 0;
        }
      }
      .row {
        display: flex;
        flex-wrap: wrap;
        margin-right: -15px;
        margin-left: -15px;
        .col-lg-6 {
          position: relative;
          width: 100%;
          min-height: 1px;
          padding-right: 15px;
          padding-left: 15px;
        }
        .box {
          padding: 40px;
          margin-bottom: 40px;
          box-shadow: 10px 10px 15px rgba(73, 78, 92, 0.1);
          background: #fff;
          transition: 0.4s;
          display: flex;
          &:hover {
            box-shadow: 0px 0px 30px rgba(73, 78, 92, 0.15);
            transform: translateY(-10px);
          }
          .icon {
            color: #50d8af;
            font-size: 64px;
            transition: 0.5s;
            line-height: 0;
            margin-right: 10px;
          }
          .advantage-text {
            h4 {
              margin-top: 0px;
            }
          }
        }
      }
    }
    #appraisal {
      text-align: left;
      h2 {
        font-size: 32px;
        color: #0c2e8a;
        text-transform: uppercase;
        font-weight: 700;
        position: relative;
        &::before {
          content: '';
          position: absolute;
          display: block;
          width: 50px;
          height: 3px;
          background: #50d8af;
          bottom: 0;
          left: 0;
        }
      }
      .owl-stage {
        display: flex;
        justify-content: space-around;
        align-items: center;
        .owl-item {
          margin: 0px 10px;
          .appraisal-item {
            box-sizing: content-box;
            padding: 30px 30px 0 30px;
            margin: 30px 15px;
            text-align: center;
            min-height: 350px;
            box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.08);
            .user-icon {
              font-size: 50px;
              color: #50d8af;
            }
          }
        }
      }
    }
    #contact {
      padding: 30px 0;
      .section-header {
        text-align: left;
        h2 {
          font-size: 32px;
          color: #0c2e8a;
          text-transform: uppercase;
          font-weight: 700;
          position: relative;
          &::before {
            content: '';
            position: absolute;
            display: block;
            width: 50px;
            height: 3px;
            background: #50d8af;
            bottom: 0;
            left: 0;
          }
        }
      }
      .form-row {
        display: flex;
        flex-wrap: wrap;
        margin-right: -5px;
        margin-left: -5px;
      }
      .form-row>.col, .form-row>[class*=col-] {
        padding-right: 5px;
        padding-left: 5px;
      }
      input, textarea {
        padding: 10px 14px;
        border-radius: 0;
        box-shadow: none;
        font-size: 15px;
      }
      .form-group {
        flex: 1;
        margin-bottom: 1rem;
        .form-control {
          flex: 1;
          display: block;
          width: 100%;
          padding: 0.375rem 0.75rem;
          font-size: 1rem;
          line-height: 1.5;
          color: #495057;
          background-color: #fff;
          background-clip: padding-box;
          border: 1px solid #ced4da;
          border-radius: 0.25rem;
          transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
          &:focus {
            color: #495057;
            background-color: #fff;
            border-color: #80bdff;
            outline: 0;
          }
        }
      }
      button[type="submit"] {
        background: #50d8af;
        border: 0;
        border-radius: 3px;
        padding: 10px 30px;
        color: #fff;
        transition: 0.4s;
        cursor: pointer;
        &:hover {
          background: #2dc899;
        }
      }
    }
    #footer {
      background: #f2f5f8;
      padding: 0 0 30px 0;
      font-size: 14px;
      .copyright {
        text-align: center;
        padding-top: 30px;
      }
      .credits {
        text-align: center;
        font-size: 13px;
        color: #555;
      }
    }
  }

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto
}

@media (min-width: 576px) {
  .container {
    max-width:540px
  }
}

@media (min-width: 768px) {
  .container {
    max-width:720px
  }
}

@media (min-width: 992px) {
  .container {
    max-width:960px
  }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%
  }
}

@media (min-width: 1200px) {
  .container {
    max-width:1140px
  }
}
</style>